<template>
  <rj-feedback-form
    :question="funnel.surveyQuestionNoResponse"
    :survey-id="survey.id"
    @submited="redirect"
  />
</template>

<script>
import RjFeedbackForm from '@/views/common/RjFeedbackForm.vue';

export default {
  name: 'RjNegativeTestimonial',

  components: { RjFeedbackForm },

  props: {
    company: {
      type: Object,
      required: true,
    },

    survey: {
      type: Object,
      required: true,
    },

    contact: {
      type: Object,
      required: true,
    },

    funnel: {
      type: Object,
      required: true,
    },
  },

  methods: {
    redirect() {
      this.$router.replace({ name: 'completed-referral' });
    },
  },
};
</script>
